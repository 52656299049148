import { allocationSystemRepository } from "@/repositories/AllocationSystemRepository";
import { brandRepository } from "@/repositories/BrandRepository";
import { entityRepository } from "@/repositories/EntityRepository";
import { fiscalYearRepository } from "@/repositories/FiscalYearRepository";
import { importRepository } from "@/repositories/ImportRepository";
import { marketRepository } from "@/repositories/MarketRepository";
import { organizationRepository } from "@/repositories/OrganizationRepository";
import { productRepository } from "@/repositories/ProductRepository";
import { requestRepository } from "@/repositories/RequestRepository";
import { stepRepository } from "@/repositories/StepRepository";
import { userRepository } from "@/repositories/UserRepository";

export {
    allocationSystemRepository,
    brandRepository,
    entityRepository,
    fiscalYearRepository,
    importRepository,
    marketRepository,
    organizationRepository,
    productRepository,
    requestRepository,
    stepRepository,
    userRepository,
};

