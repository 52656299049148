import { apiService } from "@/services/ApiService";
import { cacheService } from "@/services/CacheService";
import { errorService } from "@/services/ErrorService";
import { graphService } from "@/services/GraphService";
import { graphScopes, msalInstance, msalScopes } from "@/services/MsalService";
import { notificationService } from "./NotificationService";

export {
    apiService,
    cacheService,
    errorService,
    graphService,
    msalInstance,
    msalScopes,
    graphScopes,
    notificationService,
};
